import { useEffect } from 'react'
import { ApolloClient, useApolloClient } from '@apollo/client'
import { getCustomerType, trackGAEvent } from '@moonpig/web-core-analytics'
import { gql, GraphQLContext } from '@moonpig/web-core-graphql'
import { logger } from '@moonpig/web-core-monitoring'
import { useGoogleOneTap } from '@moonpig/web-core-auth'

type UserDataQuery = {
  me: {
    customer: {
      id: string
      orders: {
        totalCount: number
      }
    }
  }
}

const userDataQuery = gql`
  query UserDataQuery {
    me {
      __typename
      customer {
        __typename
        id
        orders(limit: 1) {
          __typename
          totalCount
        }
      }
    }
  }
`

const queryUserData = async (
  query: ApolloClient<GraphQLContext>['query'],
): Promise<{
  id: string
  totalCount: number
}> => {
  const result = await query<UserDataQuery>({
    query: userDataQuery,
    errorPolicy: 'all',
  })
  const { data, errors } = result

  if (errors && errors.length) {
    throw new Error(errors.map(error => error.toString()).join(', '))
  }

  const { customer } = data.me

  return {
    id: customer.id,
    totalCount: customer.orders.totalCount,
  }
}

export const SendGAUserData = () => {
  const client = useApolloClient()
  const oneTapState = useGoogleOneTap()

  useEffect(() => {
    const send = async () => {
      try {
        const userDataType = localStorage?.getItem('mnpg_send_ga_user_data')

        if (!userDataType) {
          return
        }

        const userData = await queryUserData(client.query)
        localStorage.setItem('mnpg_ga_user_data', JSON.stringify(userData))

        trackGAEvent({
          event: 'customer',
          user_data: {
            customer_id: userData.id,
            is_logged_in: 'true',
            lifetime_order_count: userData.totalCount,
            customer_type: getCustomerType(userData.totalCount),
            login_method: 'email',
          },
          event_data: {
            category: 'account',
            action: 'customer authentication',
            label: `email | ${userData.id}`,
            non_interaction: true,
            value: undefined,
          },
          content_data: undefined,
          discount_data: undefined,
          ecommerce: undefined,
          error_data: undefined,
          // For GTM //
          application_data: {
            datalayer_version: 1,
          },
        })

        localStorage.removeItem('mnpg_send_ga_user_data')
      } catch (e) {
        logger.warning(`Could not send GA user data`, {}, e)
      }
    }

    send()
  }, [client.query, oneTapState])

  return null
}
