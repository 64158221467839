export const experimentCampaigns = {
  'attach-basket-xsell-algorithm': [],
  'browse-dynamic-hero-validation': [],
  'client-side-routing': [],
  'gallery-direct-smile-impact': [],
  'loyalty-cards-filter': ['ShowCardsFilter', 'Default'],
  'loyalty-show-mp-plus-selector': [],
  'search-use-new-ia-index-v3': [],
  'shop-on-app-pop-up': [],
  'show-gift-vouchers': [],
  'xsell-algorithm': [],
  'site-xsell-algorithm': [],
  'browse-tabbed-carousels': [],
  'pa-aa-test-one': [],
  'pa-aa-test-two': [],
  'pa-aa-test-three': [],
  'browse-contentful-caching': [],
  'customer-credit-nav': [],
  'new-pa-aa-test-one': [],
  'new-pa-aa-test-two': [],
  'new-pa-aa-test-three': [],
  'customer-review-and-pay-recipient-tracking': [],
  'gallery-cta-experiment': [],
  'browse-navigation-caching': [],
  'browse-upcoming-occasions-mvt': [],
  'browse-mega-nav-ia-experiment': [],
  'browse-login-tooltip': [],
  'attach-gift-x-sell-carousel-tabs': [],
  'search-dynamic-placeholder': [],
  'loyalty-mp-plus-499': [],
  'search-gift-x-sell-carousel': [],
  'attach-product-video': ['Original', 'enabled'],
  'subscriptions-sign-up-component': ['Original', 'VariantA', 'VariantB'],
  'loyalty-plus-in-basket-redesign-v2': ['Original', 'Variant'],
  'search-show-externally-sponsored-products': ['Original', 'Enabled'],
  'browse-modal-animation': ['Original', 'variant'],
  'search-use-shared-ranking-v2': ['Original', 'Enabled'],
  'editor-cropping': [],
  'editor-skip-inside-left': [],
  'search-show-externally-sponsored-products-v2': ['Original', 'Enabled'],
  'attach-first-name-cross-sell-header': ['Original', 'enabled'],
  'explore-xsell-show-exit-point-carousels': [
    'Original',
    'brand-top',
    'category-top',
  ],
  'editor-sticker-promotion': ['Control', 'V1', 'V2'],
  'search-use-ai-data': ['Original', 'Enabled'],
  'attach-personalised-promotion-model': ['Original', 'enabled'],
  'tagg-experience': ['control', 'treatment-v1', 'treatment-v2'],
  'customer-order-conf-redesign': ['Original', 'enabled'],
  'search-use-ai-generated-data': ['Original', 'Enabled'],
  'mnpg-price-trial': ['Original', 'trial_a', 'trial_b', 'trial_c'],
  'customer-google-one-tap': ['Original', 'enabled'],
  'fulfilment-cards-tracked-delivery-default': ['Original', 'enabled'],
  'search-quick-filter-toggles': ['Original', 'Enabled'],
  'checkout-improved-delivery-messaging': ['Original', 'enabled'],
  'search-use-dropdown-filters': ['Original', 'Enabled'],
  'search-ranking-top-100': ['Original', 'Enabled'],
  'explore-editable-card-content': ['Original', 'enabled'],
  'checkout-exp-nc-promo': ['Original', 'enabled'],
  'search-use-vector-boosted-ranking': ['Original', 'Enabled'],
  'explore-upsell-redesign': ['Original', 'enabled'],
  'fulfilment-pricing-t24-discount-sep-2024': [
    'Original',
    'variant299',
    'variant249',
  ],
  'search-mixed-category-nbas': ['Original', 'Enabled'],
  'search-new-gallery-ux': ['Original', 'Enabled'],
  'fulfilment-pricing-1C-increase-Oct-2024': [
    '1C-original',
    '1C-variant145',
    '1C-variant155',
    '1C-variant165',
  ],
  'fulfilment-pricing-T24-Large-Oct-2024': [
    'T24Large-original',
    'T24Large-variant349',
  ],
}

export const getExperimentCampaigns = () => Object.keys(experimentCampaigns)
