import { gql, GraphQLContext } from '@moonpig/web-core-graphql'
import { Region } from '@moonpig/web-core-types'
import { getSearchContext } from '@moonpig/web-core-utils'
import { DefaultLayout } from '../components/DefaultLayout'
import { HideSearchBarOption } from '../components/DefaultLayout/types'
import { NavQuery, NavQueryVariables } from './__generated__/layout'

type LayoutType = 'none' | 'default' | 'checkout'

type LayoutProps = React.ComponentProps<typeof DefaultLayout>

type FooterProps = LayoutProps['footer']

type NavItems = LayoutProps['navItems']

export type LayoutInitialProps =
  | {
      type: 'default'
      navItems: NavItems
      footerItems: FooterProps
      shouldHideFooter?: boolean
      hideSearchBar?: HideSearchBarOption
      hideGoogleOneTap?: boolean
      region: Region
      searchDepartment?: {
        name: string
        title: string
      }
      searchTerm?: string
      headerLogoIconUrl?: string
    }
  | {
      type: 'checkout'
      region: Region
      headerLogoIconUrl?: string
    }
  | {
      type: 'none'
    }

export const navQuery = gql`
  query NavQuery($navigationName: String!, $preview: Boolean) {
    navigation(name: $navigationName, preview: $preview) {
      __typename
      items {
        __typename
        title
        icon
        url
        items {
          __typename
          title
          items {
            __typename
            title
            url
          }
        }
      }
    }
    footer: navigation(name: "web-footer", preview: $preview) {
      __typename
      items {
        __typename
        title
        items {
          __typename
          title
          url
        }
      }
    }
    content {
      __typename
      headerLogo {
        __typename
        icon {
          __typename
          url(width: 100, height: 100, format: png)
          description
          dimensions {
            __typename
            width
            height
          }
        }
      }
    }
  }
`

const transformNavAndFooter = (data: NavQuery) => {
  const navItems: NavItems = data.navigation.items.map(topLevelItem => ({
    to: topLevelItem.url,
    label: topLevelItem.title,
    icon: topLevelItem.icon,
    dropdown: topLevelItem.items.map(secondLevelItem => ({
      title: secondLevelItem.title,
      items: secondLevelItem.items.map(thirdLevelItem => ({
        to: thirdLevelItem.url,
        label: thirdLevelItem.title,
      })),
    })),
  }))

  const footerItems: FooterProps = data.footer.items.map(topLevelItem => ({
    title: topLevelItem.title,
    items: topLevelItem.items.map(secondLevelItem => ({
      title: secondLevelItem.title,
      linkHref: secondLevelItem.url,
    })),
  }))

  return {
    navItems,
    footerItems,
  }
}

const getHeaderLogoIconUrl = (data: NavQuery) => {
  const { headerLogo } = data.content
  if (!headerLogo) return undefined

  const { icon } = headerLogo
  if (!icon) return undefined

  return icon.url
}

export const getLayoutInitialProps = async ({
  graphQL,
  path,
  query,
  region,
  preview,
  layoutType,
  shouldHideFooter,
  hideSearchBar,
  hideGoogleOneTap,
  megaNavExperimentEnabled,
}: {
  graphQL: { query: GraphQLContext['query'] }
  region: Region
  path: string
  preview: boolean
  query: { [key: string]: string | string[] | undefined }
  layoutType: LayoutType
  shouldHideFooter?: boolean
  hideSearchBar?: HideSearchBarOption
  hideGoogleOneTap?: boolean
  megaNavExperimentEnabled?: boolean
}): Promise<LayoutInitialProps> => {
  if (layoutType === 'none') {
    return {
      type: 'none',
    }
  }

  const result = await graphQL.query<NavQuery, NavQueryVariables>({
    query: navQuery,
    variables: {
      preview,
      navigationName: megaNavExperimentEnabled
        ? 'web-navigation-experiment'
        : 'web-navigation',
    },
  })

  const headerLogoIconUrl = getHeaderLogoIconUrl(result.data)
  const { navItems, footerItems } = transformNavAndFooter(result.data)

  const searchContext = getSearchContext(path, query)

  if (layoutType === 'checkout') {
    return {
      type: 'checkout',
      region,
      headerLogoIconUrl,
    }
  }

  return {
    type: 'default',
    navItems,
    footerItems,
    shouldHideFooter,
    hideSearchBar,
    hideGoogleOneTap,
    region,
    searchDepartment: searchContext.department,
    searchTerm: searchContext.searchTerm,
    headerLogoIconUrl,
  }
}
