import { defineRoute } from './route'

/*
  🚨 Before making changes to routing... 🚨

  Native apps may be affected by routing changes e.g. deep links - please notify the team of intended changes on #cards-app-help (https://moonpig-group.slack.com/archives/CSQFKLPPE).
 */

const commonParams = {
  region: 'path.region',
  preview: 'query.boolean.optional',
  feature: 'query.string.multiple',
  flag: 'query.string.multiple',
  variation: 'query.string.multiple',
  cst_mlt: 'query.string.optional',
} as const

const searchParams = {
  suggestion: 'query.string.optional',
  filters: 'query.string.optional',
  parts: 'path.string.multiple',
  success: 'query.string.optional',
  uqd: 'query.string.optional',
  d: 'query.string.optional',
  q: 'query.string.optional',
  promotionId: 'query.string.optional',
  productId: 'query.string.optional',
  page: 'query.number.optional',
  groupCardProject: 'query.string.optional',
  // legacy parameters
  product_categories: 'query.string.optional',
  f: 'query.string.optional',
  fq: 'query.string.optional',
} as const

// nb. ordering is important as routing works on a "first match" basis
export const routes = {
  reminders: defineRoute(
    {
      ...commonParams,
    },
    p => `/${p.region}/reminders`,
    'customer',
  ),
  'see-more': defineRoute(
    {
      ...commonParams,
      con: 'query.string.optional',
      department: 'query.string.optional',
      productSearchLimit: 'query.number.optional',
    },
    p => `/${p.region}/add-a-gift/see-more`,
    'purchase',
  ),
  'add-a-gift': defineRoute(
    {
      ...commonParams,
      con: 'query.string.optional',
      moonpigProductNo: 'query.string.optional',
      'xsell-algorithm': 'query.string.optional',
      'see-all-xsell': 'query.string.optional',
    },
    p => `/${p.region}/add-a-gift`,
    'purchase',
  ),
  'add-a-card': defineRoute(
    {
      ...commonParams,
      con: 'query.string.optional',
      moonpigProductNo: 'query.string.optional',
      quantity: 'query.number.optional',
      version: 'query.number.optional',
    },
    p => `/${p.region}/add-a-card`,
    'purchase',
  ),
  addresses: defineRoute(
    {
      ...commonParams,
      lineItemId: 'query.string.optional',
      deliveryId: 'query.string.optional',
      productKey: 'query.string.optional',
    },
    p => `/${p.region}/addresses`,
    'purchase',
  ),
  'multi-address': defineRoute(
    {
      ...commonParams,
    },
    p => `/${p.region}/multi-address`,
    'purchase',
  ),
  'ecard-details': defineRoute(
    {
      ...commonParams,
      lineItemId: 'query.string.optional',
      productKey: 'query.string.optional',
    },
    p => `/${p.region}/ecard-details`,
    'purchase',
  ),
  'reminder-inflow': defineRoute(
    {
      ...commonParams,
      redirectUrl: 'query.string',
      productKey: 'query.string',
    },
    p => `/${p.region}/reminder-inflow`,
    'customer',
  ),
  'reminder-share': defineRoute(
    {
      ...commonParams,
      payload: 'path.string',
    },
    p => `/${p.region}/reminder-share/${p.payload}`,
    'customer',
  ),
  'reminder-unsubscribe': defineRoute(
    {
      ...commonParams,
      payload: 'path.string',
    },
    p => `/${p.region}/reminder-unsubscribe/${p.payload}`,
    'customer',
  ),
  basket: defineRoute(
    {
      ...commonParams,
      uqd: 'query.string.optional',
      quantity: 'query.number.optional',
    },
    p => `/${p.region}/basket`,
    'purchase',
  ),
  'order-confirmation': defineRoute(
    {
      id: 'path.string',
      ...commonParams,
    },
    p => `/${p.region}/order-confirmation/${p.id}`,
    'purchase',
  ),
  checkout: defineRoute(
    {
      ...commonParams,
      domain: 'path.string.optional',
    },
    p => `/${p.region}/checkout${p.domain}`,
    'purchase',
  ),
  pay: defineRoute(
    {
      ...commonParams,
      payment_intent: 'query.string.optional',
      redirect_status: 'query.string.optional',
    },
    p => `/${p.region}/pay`,
    'purchase',
  ),
  'payment-methods': defineRoute(
    {
      ...commonParams,
      redirectResult: 'query.string.optional',
    },
    p => `/${p.region}/payment-methods`,
    'purchase',
  ),
  'review-pay': defineRoute(
    {
      ...commonParams,
    },
    p => `/${p.region}/review-pay`,
    'purchase',
  ),
  newbuy: defineRoute(
    {
      ...commonParams,
    },
    p => `/${p.region}/newbuy`,
    'purchase',
  ),
  'product-soldout': defineRoute(
    {
      ...commonParams,
    },
    p => `/${p.region}/product-soldout`,
    'purchase',
  ),
  login: defineRoute(
    {
      ...commonParams,
      returnUrl: 'query.string.optional',
    },
    p => `/${p.region}/account/login`,
    'purchase',
  ),
  'login-callback': defineRoute(
    { ...commonParams },
    p => `/${p.region}/account/login/callback`,
    'purchase',
  ),
  'reset-password': defineRoute(
    {
      ...commonParams,
      token: 'query.string.optional',
    },
    p => `/${p.region}/account/reset-password`,
    'customer',
  ),
  'forgotten-password': defineRoute(
    {
      ...commonParams,
      'invalid-token': 'query.string.optional',
    },
    p => `/${p.region}/account/forgotten`,
    'customer',
  ),
  'order-details': defineRoute(
    {
      ...commonParams,
      id: 'path.string',
    },
    p => `/${p.region}/account/orders/${p.id}`,
    'customer',
  ),
  'membership-details': defineRoute(
    {
      ...commonParams,
    },
    p => `/${p.region}/account/membership`,
    'customer',
  ),
  support: defineRoute(
    {
      ...commonParams,
      id: 'path.string',
    },
    p => `/${p.region}/account/orders/${p.id}/support`,
    'customer',
  ),
  orders: defineRoute(
    {
      ...commonParams,
    },
    p => `/${p.region}/account/orders`,
    'customer',
  ),
  tracking: defineRoute(
    {
      ...commonParams,
      id: 'path.string',
      success: 'query.boolean.optional',
    },
    p => `/${p.region}/tracking/${p.id}`,
    'purchase',
  ),
  'address-book': defineRoute(
    {
      ...commonParams,
    },
    p => `/${p.region}/account/address-book`,
    'customer',
  ),
  myaccount: defineRoute(
    {
      ...commonParams,
    },
    p => `/${p.region}/myaccount`,
    'customer',
  ),
  'account-details': defineRoute(
    {
      ...commonParams,
      updated: 'query.boolean.optional',
    },
    p => `/${p.region}/account/account-details`,
    'customer',
  ),
  'account-details-edit': defineRoute(
    {
      ...commonParams,
    },
    p => `/${p.region}/account/account-details/edit`,
    'customer',
  ),
  'saved-cards': defineRoute(
    {
      ...commonParams,
    },
    p => `/${p.region}/account/savedpaymentcardsdetails`,
    'customer',
  ),
  favourites: defineRoute(
    {
      ...commonParams,
    },
    p => `/${p.region}/account/favourites`,
    'customer',
  ),
  'account-reminders': defineRoute(
    { ...commonParams },
    p => `/${p.region}/account/reminders`,
    'customer',
  ),
  'reminder-landing': defineRoute(
    {
      ...commonParams,
      occasion: 'query.string.optional',
      relationship: 'query.string.optional',
      uqd: 'query.string.optional',
      id: 'path.string',
    },
    p => `/${p.region}/account/reminders/${p.id}`,
    'customer',
  ),
  media: defineRoute(
    {
      key: 'query.string.optional',
      ...commonParams,
    },
    p => `/${p.region}/media`,
    'personalise',
  ),
  customise: defineRoute(
    {
      id: 'path.string',
      variantKey: 'path.string',
      productVariantSku: 'query.string.optional',
      addonSku: 'query.string.optional',
      quantity: 'query.number.optional',
      journey: 'query.string.optional',
      continueUrl: 'query.string.optional',
      disablePreview: 'query.boolean.optional',
      exitUrl: 'query.string.optional',
      ...commonParams,
    },
    p => `/${p.region}/customise/product/${p.id}/${p.variantKey}`,
    'personalise',
  ),
  'customise-edit': defineRoute(
    {
      designId: 'path.string',
      variantKey: 'query.string.optional',
      journey: 'query.string.optional',
      continueUrl: 'query.string.optional',
      disablePreview: 'query.boolean.optional',
      exitUrl: 'query.string.optional',
      ...commonParams,
    },
    p => `/${p.region}/customise/edit/${p.designId}`,
    'personalise',
  ),
  'customise-duplicate': defineRoute(
    {
      designId: 'path.string',
      variantKey: 'path.string',
      journey: 'query.string.optional',
      continueUrl: 'query.string.optional',
      disablePreview: 'query.boolean.optional',
      exitUrl: 'query.string.optional',
      ...commonParams,
    },
    p => `/${p.region}/customise/duplicate/${p.designId}/${p.variantKey}`,
    'personalise',
  ),
  'group-cards-add-message': defineRoute(
    {
      addMessageToken: 'path.string',
      ...commonParams,
    },
    p => `/${p.region}/customise/group-card-message/${p.addMessageToken}`,
    'personalise',
  ),
  'group-cards-create-project': defineRoute(
    {
      ...commonParams,
      productId: 'query.string',
      productVariantSku: 'query.string',
    },
    p => `/${p.region}/group-cards/create`,
    'personalise',
  ),
  'group-cards-edit-project': defineRoute(
    {
      projectId: 'path.string',
      productId: 'query.string.optional',
      productVariantSku: 'query.string.optional',
      ...commonParams,
    },
    p => `/${p.region}/group-cards/${p.projectId}`,
    'personalise',
  ),
  'group-cards-collect-messages': defineRoute(
    {
      messageToken: 'path.string',
      view: 'query.number.optional',
      ...commonParams,
    },
    p => `/${p.region}/group-cards/collect/${p.messageToken}`,
    'personalise',
  ),
  'group-cards-dashboard': defineRoute(
    {
      show: 'query.string.optional',
      productId: 'query.string.optional',
      productVariantSku: 'query.string.optional',
      parts: 'path.string.multiple',
      ...commonParams,
    },
    p => `/${p.region}/group-cards${p.parts}`,
    'personalise',
  ),
  'work-create-project': defineRoute(
    {
      designUploaded: 'query.boolean.optional',
      designCancelled: 'query.boolean.optional',
      recipientLimit: 'query.number.optional',
      ...commonParams,
    },
    p => `/${p.region}/work/create-project`,
    'personalise',
  ),
  'work-order-confirmation': defineRoute(
    {
      orderId: 'path.string',
      ...commonParams,
    },
    p => `/${p.region}/work/order-confirmation/${p.orderId}`,
    'personalise',
  ),
  'work-orders': defineRoute(
    {
      ...commonParams,
    },
    p => `/${p.region}/work/orders/`,
    'personalise',
  ),
  'dev-customer': defineRoute(
    {
      ...commonParams,
    },
    () => `/dev-customer`,
    'customer',
  ),
  'dev-customer-login': defineRoute(
    {
      ...commonParams,
      returnUrl: 'query.string.optional',
    },
    () => `/dev-customer/login`,
    'customer',
  ),
  'dev-customer-register': defineRoute(
    {
      ...commonParams,
      returnUrl: 'query.string.optional',
    },
    () => `/dev-customer/register`,
    'customer',
  ),
  'dev-customer-add-to-basket': defineRoute(
    commonParams,
    () => `/dev-customer/add-to-basket`,
    'customer',
  ),
  ecard: defineRoute(
    {
      ...commonParams,
      key: 'query.string.optional', // Renderer API access key
      cid: 'query.string.optional', // Legacy encrypted customer ID
      con: 'query.string.optional', // Legacy customer order number
      CardNo: 'query.string.optional', // Legacy card ID
      productSizeId: 'query.string.optional', // Legacy product size ID
    },
    p => `/${p.region}/ecard`,
    'personalise',
  ),
  home: defineRoute(
    {
      ...commonParams,
    },
    () => `/`,
    'explore',
  ),
  'dev-explore': defineRoute(
    {
      ...commonParams,
    },
    () => `/dev-explore`,
    'explore',
  ),
  'dev-purchase': defineRoute(
    {
      ...commonParams,
    },
    () => `/dev-purchase`,
    'purchase',
  ),
  'dev-personalise': defineRoute(
    {
      ...commonParams,
    },
    () => `/dev-personalise`,
    'personalise',
  ),
  'dev-personalise-login': defineRoute(
    {
      ...commonParams,
      returnUrl: 'query.string.optional',
    },
    () => `/dev-personalise/login`,
    'personalise',
  ),
  'product-details': defineRoute(
    {
      departmentSlug: 'path.string',
      slug: 'path.string',
      id: 'path.string',
      parts: 'path.string.multiple',
      size: 'query.string.optional',
      groupCardProject: 'query.string.optional',
      uqd: 'query.string.optional',
      ...commonParams,
    },
    p => `/${p.region}/${p.departmentSlug}/p/${p.slug}/${p.id}${p.parts}`,
    'explore',
  ),
  search: defineRoute(
    {
      ...searchParams,
      ...commonParams,
    },
    p => `/${p.region}/search${p.parts}`,
    'explore',
  ),
  dynamic: defineRoute(
    {
      ...searchParams,
      ...commonParams,
    },
    p => `/${p.region}/d${p.parts}`,
    'explore',
  ),
  'pay-per-click': defineRoute(
    {
      ...searchParams,
      ...commonParams,
      ds_kw: 'query.string.optional',
    },
    p => `/${p.region}/v${p.parts}`,
    'explore',
  ),
  'digital-gift-card-options': defineRoute(
    {
      productId: 'path.string',
      ...commonParams,
    },
    p => `/${p.region}/card-options/${p.productId}`,
    'explore',
  ),
  'digital-gift-redeem': defineRoute(
    {
      redemptionId: 'path.string',
      ...commonParams,
    },
    p => `/${p.region}/redeem/${p.redemptionId}`,
    'explore',
  ),
  'upgrade-your-card': defineRoute(
    { ...commonParams },
    p => `/${p.region}/upgrade-your-card`,
    'purchase',
  ),
  content: defineRoute(
    {
      parts: 'path.string.multiple',
      page: 'query.number.optional',
      filters: 'query.string.optional',
      success: 'query.string.optional',
      uqd: 'query.string.optional',
      d: 'query.string.optional',
      q: 'query.string.optional',
      promotionId: 'query.string.optional',
      productId: 'query.string.optional',
      offset: 'query.number.optional',
      // legacy parameters
      product_categories: 'query.string.optional',
      f: 'query.string.optional',
      fq: 'query.string.optional',
      ...commonParams,
    },
    p => `/${p.region}${p.parts}`,
    'explore',
  ),
  status: defineRoute({}, () => '/status', 'purchase'),
  error404: defineRoute({}, () => `/404`, 'explore'),
}
