import { Locale, Store } from '@moonpig/web-core-types'
import {
  CardFilterOption,
  FooterConfig,
  InterestFacetMapping,
  ProductTabType,
} from '../types'
import { createWebLoyaltyConfig } from '../shared'
import thuiswinkel from '../../assets/greetz/legal/thuiswinkel.svg'

const STORE_ID_NETHERLANDS = 'nl'

const LOCALE_NL_NL: Locale = {
  language: 'nl-NL',
  countryCode: 'nl',
  currencyCode: 'eur',
  timeZone: 'Europe/Amsterdam',
}

const GTM_CLIENT = {
  containerId: 'GTM-T83BCTC',
  host: 'www.googletagmanager.com',
}

export const STORES: Store[] = [
  {
    id: STORE_ID_NETHERLANDS,
    legacyId: 'grtz-nl',
    shortName: 'Netherlands',
    name: 'Netherlands',
    locale: LOCALE_NL_NL,
    analytics: {
      gtmClient: GTM_CLIENT,
    },
    webLoyalty: createWebLoyaltyConfig(STORE_ID_NETHERLANDS),
    supportsWork: false,
  },
]

const API_ENV = process.env.MNPG_ENVIRONMENT_NAME as string
const API_REGIONS = ['eu-central-1', 'eu-west-1']

const GOOGLE_AUTH_CLIENT_IDS: { [key: string]: string } = {
  local:
    '919866866975-t53qcc36t118njopufcjg0jc0per59nf.apps.googleusercontent.com',
  dev: '882969184013-r0l94f2jf153fdphts2ql0vtfsvv34gm.apps.googleusercontent.com',
  uat: '919866866975-t53qcc36t118njopufcjg0jc0per59nf.apps.googleusercontent.com',
  prod: '349698874997-krhe279j95pbojm1sh98632vklhm48jl.apps.googleusercontent.com',
}

export const GOOGLE_AUTH_CLIENT_ID: string | undefined =
  GOOGLE_AUTH_CLIENT_IDS[API_ENV]

const MEDIA_API_HOST_NAMES = [
  ...API_REGIONS.map(
    region =>
      `grtz-media-assets-${region}-${API_ENV}-uploaded.s3.${region}.amazonaws.com`,
  ),
  ...API_REGIONS.map(
    region =>
      `grtz-media-assets-${region}-${API_ENV}-converted.s3.${region}.amazonaws.com`,
  ),
]

const DESIGN_API_HOST_NAMES = API_REGIONS.map(
  region =>
    `grtz-design-api-${region}-${API_ENV}-image-upload.s3.${region}.amazonaws.com`,
)

export const contentSecurityPolicy: { [key: string]: string[] } = {
  'font-src': ['*.greetz-nl.net', '*.greetz.nl'],
  'connect-src': [
    ...DESIGN_API_HOST_NAMES,
    ...MEDIA_API_HOST_NAMES,
    '*.greetz-nl.net',
    '*.greetz.nl',
    'ekr.zdassets.com',
    'greetznl.zendesk.com',
    'wss://widget-mediator.zopim.com',
  ],
  'frame-src': [],
  'style-src': ['*.greetz-nl.net', '*.greetz.nl'],
  'script-src': ['*.greetz-nl.net', '*.greetz.nl', 'static.zdassets.com'],
  'img-src': [],
  'media-src': [
    ...MEDIA_API_HOST_NAMES,
    '*.greetz-nl.net',
    'static.zdassets.com',
    'videos.ctfassets.net',
  ],
}

export const ONE_TRUST_DOMAIN_SCRIPT_ID = '5f5d2726-5419-4c0b-a667-340201a1671c'

export const BRAND_LABEL = 'Greetz'

export const DEPARTMENT_PATHS = {
  gifts: 'cadeaus',
  cards: 'wenskaarten',
  'flowers-and-plants': 'bloemen-en-planten',
}

export const FOOTER: FooterConfig = {
  socialItems: {
    nl: [
      {
        icon: 'facebook',
        linkHref: 'https://www.facebook.com/greetznl/',
        label: 'Facebook',
      },
      {
        icon: 'twitter',
        linkHref: 'https://twitter.com/greetznl',
        label: 'Twitter',
      },
      {
        icon: 'youtube',
        linkHref: 'https://www.youtube.com/user/GreetzNL',
        label: 'YouTube',
      },
      {
        icon: 'linkedin',
        linkHref: 'https://www.linkedin.com/company/greetz',
        label: 'LinkedIn',
      },
    ],
  },
  appItems: [
    {
      id: 'apple',
      icon: 'apple-app-store',
      to: 'https://apps.apple.com/gb/app/greetz-kaarten-en-cadeaus/id431213959',
      width: '120px',
      height: '40px',
    },
    {
      id: 'google',
      icon: 'google-app-store',
      to: 'https://play.google.com/store/apps/details?id=net.sharewire.greetz&hl=en_GB&gl=US',
      width: '135px',
      height: '40px',
    },
  ],
  paymentProviders: [
    'Ideal',
    'PayPal',
    'American Express',
    'Visa',
    'Mastercard',
  ],
  legal: {
    image: {
      src: thuiswinkel,
      description: 'Thuiswinkel Waarborg Gecertificeerd',
      link: 'https://www.thuiswinkel.org/leden/greetz-b-v/certificaat/',
    },
    htmlString:
      "Alle prijzen zijn inclusief btw en andere heffingen. Lees de <a href='/nl/voorwaarden/'>algemene voorwaarden</a>.",
  },
}

export const TRUSTPILOT_BUSINESS_UNIT_ID = '49fcfbe00000640005044023'

export const interestFacetsMappings: InterestFacetMapping[] = [
  {
    interestId: 'beer_lover',
    interestName: 'Bier liefhebber',
    facetKeys: ['beer-cider'],
    includeRecipient: true,
  },
  {
    interestId: 'wine_connoissuer',
    interestName: 'Wijnkenner',
    facetKeys: [
      'champagne-prosecco-wine',
      'other-spirits',
      'alcohol-gift-sets-letterbox',
    ],
    includeRecipient: true,
  },
  {
    interestId: 'gin_and_spirits_fan',
    interestName: 'Gin- en gedistilleerd-genieter',
    facetKeys: ['gin', 'vodka', 'other-spirits', 'whiskey'],
    includeRecipient: false,
  },
  {
    interestId: 'sweet_tooth',
    interestName: 'Zoetekauw',
    facetKeys: ['chocolate', 'sweets', 'baking-kits'],
    includeRecipient: true,
  },
  {
    interestId: 'game_and_puzzle_solver',
    interestName: 'Puzzelaar',
    facetKeys: ['games-gadgets-novelty-games-board-games-puzzles', 'gadgets'],
    includeRecipient: true,
  },
  {
    interestId: 'jewellery',
    interestName: 'Sieradenlover',
    facetKeys: ['jewellery'],
    includeRecipient: false,
  },
  {
    interestId: 'home_stylist',
    interestName: 'Interieurstylist',
    facetKeys: ['home-accessories'],
    includeRecipient: true,
  },
  {
    interestId: 'all_flowers_and_plants',
    interestName: 'Bloemen liefhebber',
    facetKeys: ['product-type-flowers-plants'],
    includeRecipient: true,
  },
  {
    interestId: 'gift_card_lover',
    interestName: 'Cadeaubon liefhebber',
    facetKeys: ['product-type-gift-cards'],
    includeRecipient: true,
  },
  {
    interestId: 'beauty_enthusiast',
    interestName: 'Beautybewust',
    facetKeys: ['product-type-beauty-face-body'],
    includeRecipient: true,
  },
  {
    interestId: 'experience_fan',
    interestName: 'Belevingszoeker',
    facetKeys: ['all-gift-experiences'],
    includeRecipient: true,
  },
]

const CARD_FILTER_OCCASIONS: CardFilterOption[] = [
  {
    optionId: 'occasion-birthday',
    optionName: 'Verjaardag',
    category: 'occasion',
    isSelected: false,
  },
  {
    optionId: 'occasion-get-well',
    optionName: 'Beterschap',
    category: 'occasion',
    isSelected: false,
  },
  {
    optionId: 'occasion-new-baby',
    optionName: 'Geboorte',
    category: 'occasion',
    isSelected: false,
  },
  {
    optionId: 'occasion-sympathy',
    optionName: 'Condoleance',
    category: 'occasion',
    isSelected: false,
  },
  {
    optionId: 'occasion-thinking-of-you',
    optionName: 'Denken aan',
    category: 'occasion',
    isSelected: false,
  },
  {
    optionId: 'just-to-say',
    optionName: 'Zomaar',
    category: 'occasion',
    isSelected: false,
  },
  {
    optionId: 'occasion-new-home',
    optionName: 'Nieuwe woning',
    category: 'occasion',
    isSelected: false,
  },
  {
    optionId: 'occasion-thank-you',
    optionName: 'Bedankt',
    category: 'occasion',
    isSelected: false,
  },
]

const CARD_FILTER_RECIPIENTS = [
  {
    optionId: 'for-him',
    optionName: 'Voor hem',
    category: 'recipient',
    isSelected: false,
  },
  {
    optionId: 'for-her',
    optionName: 'Voor haar',
    category: 'recipient',
    isSelected: false,
  },
  {
    optionId: 'for-boys',
    optionName: 'Voor jongens',
    category: 'recipient',
    isSelected: false,
  },
  {
    optionId: 'for-girls',
    optionName: 'Voor meisjes',
    category: 'recipient',
    isSelected: false,
  },
  {
    optionId: 'whos-it-for-daughter',
    optionName: 'Dochter',
    category: 'recipient',
    isSelected: false,
  },
  {
    optionId: 'whos-it-for-son',
    optionName: 'Zoon',
    category: 'recipient',
    isSelected: false,
  },
  {
    optionId: 'whos-it-for-dad',
    optionName: 'Vader',
    category: 'recipient',
    isSelected: false,
  },
  {
    optionId: 'whos-it-for-mum',
    optionName: 'Mama',
    category: 'recipient',
    isSelected: false,
  },
  {
    optionId: 'colleague',
    optionName: 'Collega',
    category: 'recipient',
    isSelected: false,
  },
  {
    optionId: 'whos-it-for-grandad',
    optionName: 'Opa',
    category: 'recipient',
    isSelected: false,
  },
  {
    optionId: 'whos-it-for-grandma',
    optionName: 'Oma',
    category: 'recipient',
    isSelected: false,
  },
]

const CARD_FILTER_SENTIMENT = [
  {
    optionId: 'sentiment-style-funny',
    optionName: 'Grappig',
    category: 'sentiment',
    isSelected: false,
  },
  {
    optionId: 'sentiment-style-floral',
    optionName: 'Bloemen',
    category: 'sentiment',
    isSelected: false,
  },
  {
    optionId: 'illustration',
    optionName: 'Illustratie',
    category: 'sentiment',
    isSelected: false,
  },
  {
    optionId: 'verse',
    optionName: 'Gedicht',
    category: 'sentiment',
    isSelected: false,
  },
  {
    optionId: 'sentiment-style-love',
    optionName: 'Liefde',
    category: 'sentiment',
    isSelected: false,
  },
  {
    optionId: 'artistic',
    optionName: 'Artistiek',
    category: 'sentiment',
    isSelected: false,
  },
  {
    optionId: 'vintage',
    optionName: 'Vintage',
    category: 'sentiment',
    isSelected: false,
  },
  {
    optionId: 'sentiment-style-retro',
    optionName: 'Retro',
    category: 'sentiment',
    isSelected: false,
  },
  {
    optionId: 'sentiment-style-congratulations',
    optionName: 'Gefeliciteerd',
    category: 'sentiment',
    isSelected: false,
  },
  {
    optionId: 'sentiment-style-cute',
    optionName: 'Schattigd',
    category: 'sentiment',
    isSelected: false,
  },
]

export const CARD_FILTER_OPTIONS = [
  ...CARD_FILTER_OCCASIONS,
  ...CARD_FILTER_RECIPIENTS,
  ...CARD_FILTER_SENTIMENT,
]

export const GIFT_UPSELL_PRODUCT_IDS: {
  originalId: string
  upsellId: string
}[] = [
  { originalId: 'choc046', upsellId: 'choc044' },
  { originalId: 'grtz611913281', upsellId: 'grtz614842507' },
  { originalId: 'grtz1142813773', upsellId: 'grtz1142787585' },
  { originalId: 'grtz1142812731', upsellId: 'grtz1142805656' },
  { originalId: 'grtz1142781677', upsellId: 'grtz1142781671' },
  { originalId: 'grtz1142808635', upsellId: 'choc023' },
  { originalId: 'choc037', upsellId: 'choc032' },
  { originalId: 'choc032', upsellId: 'choc034' },
  { originalId: 'choc034', upsellId: 'choc033' },
  { originalId: 'choc041', upsellId: 'grtz1142795539' },
  { originalId: 'choc044', upsellId: 'choc047' },
  { originalId: 'grtz614840391', upsellId: 'grtz614842507' },
  { originalId: 'grtz614842507', upsellId: 'grtz1142784938' },
  { originalId: 'grtz1142811986', upsellId: 'grtz1142811989' },
  { originalId: 'grtz1142811989', upsellId: 'choc029' },
  { originalId: 'choc054', upsellId: 'choc055' },
  { originalId: 'chocpo001', upsellId: 'grtz312646562' },
  { originalId: 'grtz312646544', upsellId: 'grtz312646562' },
  { originalId: 'grtz312646562', upsellId: 'choc051' },
  { originalId: 'chocpo003', upsellId: 'grtz312646565' },
  { originalId: 'grtz1142815208', upsellId: 'grtz1142812779' },
  { originalId: 'grtz1142783783', upsellId: 'grtz1142781656' },
  { originalId: 'grtz1142812728', upsellId: 'grtz1142811434' },
  { originalId: 'grtz1142809010', upsellId: 'grtz1142812722' },
  { originalId: 'grtz1142799723', upsellId: 'grtz1142819176' },
  { originalId: 'grtz1142799717', upsellId: 'grtz1142819186' },
  { originalId: 'grtz1142799711', upsellId: 'grtz1142819181' },
  { originalId: 'cakdo001', upsellId: 'cakdo002' },
  { originalId: 'cakdo003', upsellId: 'cakdo002' },
  { originalId: 'cakdo004', upsellId: 'cakdo002' },
  { originalId: 'cako009', upsellId: 'cako010' },
  { originalId: 'cako011', upsellId: 'cako012' },
  { originalId: 'can006', upsellId: 'grtz1142803587' },
  { originalId: 'grtz1142815173', upsellId: 'grtz1142803587' },
  { originalId: 'grtz1142813558', upsellId: 'bev011' },
  { originalId: 'grtz188845702', upsellId: 'grtz1142785031' },
  { originalId: 'grtz1142781557', upsellId: 'bev005' },
  { originalId: 'grtz648971587', upsellId: 'grtz1142801616' },
  { originalId: 'grtz1142810372', upsellId: 'grtz1142810201' },
  { originalId: 'grtz439432719', upsellId: 'grtz1142810198' },
  { originalId: 'grtz1142810201', upsellId: 'fr002' },
  { originalId: 'grtz1142810198', upsellId: 'fr001' },
  { originalId: 'grtzbeau025', upsellId: 'beau015' },
  { originalId: 'beau015', upsellId: 'beau030' },
  { originalId: 'grtzbeau026', upsellId: 'beau029' },
  { originalId: 'beau027', upsellId: 'beau016' },
  { originalId: 'grtzbeau028', upsellId: 'beau018' },
  { originalId: 'beau018', upsellId: 'beau031' },
  { originalId: 'grtz1142812979', upsellId: 'grtz1142815388' },
  { originalId: 'grtzd2142782423', upsellId: 'grtzd2142782428' },
  { originalId: 'grtzd2142782428', upsellId: 'grtzd2142782433' },
  { originalId: 'grtzd2142782393', upsellId: 'grtzd2142782398' },
  { originalId: 'grtzd2142782398', upsellId: 'grtzd2142782403' },
  { originalId: 'grtzd2142782348', upsellId: 'grtzd2142782353' },
  { originalId: 'grtzd2142782353', upsellId: 'grtzd2142782358' },
  { originalId: 'grtzd2142782498', upsellId: 'grtzd2142782503' },
  { originalId: 'grtzd2142782503', upsellId: 'grtzd2142782508' },
  { originalId: 'pchoc003', upsellId: 'pchoc004' },
  { originalId: 'pchoc004', upsellId: 'pchoc005' },
  { originalId: 'grtzd2142782333', upsellId: 'grtzd2142782338' },
  { originalId: 'grtzd2142782338', upsellId: 'grtzd2142782343' },
  { originalId: 'grtzd2142782513', upsellId: 'grtzd2142782518' },
  { originalId: 'grtzd2142782518', upsellId: 'grtzd2142782523' },
  { originalId: 'grtzd2142782558', upsellId: 'grtzd2142782563' },
  { originalId: 'grtzd2142782563', upsellId: 'grtzd2142782568' },
  { originalId: 'grtzd2142782573', upsellId: 'grtzd2142782578' },
  { originalId: 'grtzd2142782578', upsellId: 'grtzd2142782583' },
  { originalId: 'grtzd2142782453', upsellId: 'grtzd2142782458' },
  { originalId: 'grtzd2142782458', upsellId: 'grtzd2142782463' },
  { originalId: 'grtzd2142782438', upsellId: 'grtzd2142782443' },
  { originalId: 'grtzd2142782443', upsellId: 'grtzd2142782448' },
  { originalId: 'pchoc006', upsellId: 'pchoc007' },
  { originalId: 'pchoc007', upsellId: 'pchoc008' },
  { originalId: 'grtzd2142782483', upsellId: 'grtzd2142782488' },
  { originalId: 'grtzd2142782488', upsellId: 'grtzd2142782493' },
  { originalId: 'grtzd2142782363', upsellId: 'grtzd2142782368' },
  { originalId: 'grtzd2142782368', upsellId: 'grtzd2142782373' },
  { originalId: 'grtzd2142782378', upsellId: 'grtzd2142782383' },
  { originalId: 'grtzd2142782383', upsellId: 'grtzd2142782388' },
  { originalId: 'grtz1142785575', upsellId: 'bln012' },
  { originalId: 'grtz1142795503', upsellId: 'bln013' },
  { originalId: 'grtz1142785611', upsellId: 'bln011' },
  { originalId: 'grtz1142785581', upsellId: 'bln025' },
  { originalId: 'grtz1142817038', upsellId: 'bln016' },
  { originalId: 'grtz1142816988', upsellId: 'bln014' },
  { originalId: 'grtz1142817023', upsellId: 'bln015' },
  { originalId: 'bln013', upsellId: 'bln008' },
  { originalId: 'bln012', upsellId: 'grtz1142813823' },
  { originalId: 'grtz1142798036', upsellId: 'grtz1142808692' },
  { originalId: 'grtz1142795629', upsellId: 'bln004' },
  { originalId: 'grtz1142804205', upsellId: 'grtz1142806873' },
  { originalId: 'grtz1142786844', upsellId: 'grtz1142794741' },
  { originalId: 'grtz1142793696', upsellId: 'grtz1142813843' },
  { originalId: 'grtz1142793702', upsellId: 'grtz1142813848' },
  { originalId: 'grtz1142814343', upsellId: 'grtz1142813833' },
  { originalId: 'grtz1142814338', upsellId: 'grtz1142813828' },
  { originalId: 'grtz1142806843', upsellId: 'grtz1142813838' },
  { originalId: 'grtz1142804220', upsellId: 'grtz1142808713' },
  { originalId: 'grtz1142785578', upsellId: 'grtz1142789484' },
  { originalId: 'bln011', upsellId: 'grtz1142813808' },
  { originalId: 'grtz1142804211', upsellId: 'grtz1142813813' },
  { originalId: 'grtz1142786820', upsellId: 'grtz1142813803' },
  { originalId: 'grtz1142811575', upsellId: 'grtz1142813798' },
  { originalId: 'grtz1142785581', upsellId: 'grtz1142808686' },
  { originalId: 'grtz1142811563', upsellId: 'bln006' },
  { originalId: 'grtz1098869202', upsellId: 'grtz1142782993' },
  { originalId: 'grtz1142795500', upsellId: 'grtz1142813063' },
  { originalId: 'grtz1142809643', upsellId: 'grtz1142813818' },
  { originalId: 'grtz1142802060', upsellId: 'grtz1142802063' },
  { originalId: 'grtz1142805195', upsellId: 'grtz1142805198' },
  { originalId: 'grtz1142805198', upsellId: 'grtz1142805201' },
  { originalId: 'grtz1142805201', upsellId: 'grtz1142805204' },
  { originalId: 'grtz1142805204', upsellId: 'grtz1142805207' },
  { originalId: 'grtz1142805207', upsellId: 'grtz1142805210' },
  { originalId: 'grtz1142805210', upsellId: 'grtz1142805213' },
  { originalId: 'grtz1142801955', upsellId: 'grtz1142801961' },
  { originalId: 'grtz1142801961', upsellId: 'grtz1142801964' },
  { originalId: 'grtz1142801964', upsellId: 'grtz1142801967' },
  { originalId: 'grtz1142801967', upsellId: 'grtz1142801970' },
  { originalId: 'grtz1142805234', upsellId: 'grtz1142805237' },
  { originalId: 'grtz1142805237', upsellId: 'grtz1142805240' },
  { originalId: 'grtz1142805240', upsellId: 'grtz1142805243' },
  { originalId: 'grtz1142805243', upsellId: 'grtz1142805246' },
  { originalId: 'grtz1142801799', upsellId: 'grtz1142801805' },
  { originalId: 'grtz1142801805', upsellId: 'grtz1142801808' },
  { originalId: 'grtz1142801808', upsellId: 'grtz1142801811' },
  { originalId: 'grtz1142801811', upsellId: 'grtz1142801814' },
  { originalId: 'grtz1142801814', upsellId: 'grtz1142801817' },
  { originalId: 'grtz1142801817', upsellId: 'grtz1142801820' },
  { originalId: 'grtz1142801820', upsellId: 'grtz1142804613' },
  { originalId: 'grtz1142807332', upsellId: 'grtz1142807335' },
  { originalId: 'grtz1142807335', upsellId: 'grtz1142807338' },
  { originalId: 'grtz1142807338', upsellId: 'grtz1142811338' },
  { originalId: 'gc024', upsellId: 'gc025' },
  { originalId: 'gc034', upsellId: 'gc027' },
  { originalId: 'gc009', upsellId: 'gc008' },
  { originalId: 'gc032', upsellId: 'gc033' },
  { originalId: 'gc030', upsellId: 'gc031' },
  { originalId: 'gc036', upsellId: 'gc037' },
  { originalId: 'gc037', upsellId: 'gc038' },
  { originalId: 'gc039', upsellId: 'gc040' },
  { originalId: 'gc040', upsellId: 'gc041' },
  { originalId: 'gc012', upsellId: 'gc013' },
  { originalId: 'gc013', upsellId: 'gc014' },
  { originalId: 'gc026', upsellId: 'gc007' },
  { originalId: 'gc028', upsellId: 'gc029' },
  { originalId: 'gc003', upsellId: 'gc005' },
  { originalId: 'gc011', upsellId: 'gc010' },
  { originalId: 'gc010', upsellId: 'gc023' },
]

export const MULTI_CARD_LEARN_MORE_LINK =
  '/wenskaarten/een-kaart-meerdere-ontvangers/'

export const MULTI_CARD_CSV_LINK: { [key: string]: string } = {
  nl: 'https://static.web-purchase.prod.greetz-nl.net/_next/static/csv/0dc112456016442422649ebedaaf0baa/example_nl.csv',
}

export const MULTI_CARD_DISCOUNTS: {
  [key: string]: {
    label: string
    minimumQuantity: number
    discount: number
    code: string
  }[]
} = {
  nl: [
    { label: '5-9 kaarten', minimumQuantity: 5, discount: 10, code: '' },
    { label: '10-19 kaarten', minimumQuantity: 10, discount: 20, code: '' },
    {
      label: '20-29 kaarten',
      minimumQuantity: 20,
      discount: 30,
      code: '',
    },
    {
      label: '30 of meer kaarten',
      minimumQuantity: 30,
      discount: 40,
      code: '',
    },
  ],
}
export const SHOW_PLUS_HEADER_RIGHT_SIDE_IMAGE = false

export const PRODUCT_TAB_TYPE: ProductTabType = 'MULTI_BUY'

export { CARD_VARIANT_IDS_ENABLED } from './product'

export const PERSISTED_FILTERS: { [key: string]: string } = {}

export const SHOW_RUDE_TOGGLE = false
