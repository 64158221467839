import React, { FC } from 'react'
import { GlobalStyle } from '@moonpig/launchpad-components'
import { GraphQLContext } from '@moonpig/web-core-graphql'
import { Region } from '@moonpig/web-core-types'
import { useCoreFlag } from '@moonpig/web-core-flags'
import { useLocaleText } from './AppShell.locale'
import { AppBanner } from './components/AppBanner'
import { OneTrustCookieBanner } from './components/CookieBanner'
import {
  AppBannerInitialProps,
  getAppBannerInitialProps,
} from './data/appBanner'
import { getLayoutInitialProps, LayoutInitialProps } from './data/layout'
import { Layout } from './Layout'
import { DevWidget } from './components/DevWidget/DevWidget'
import { HideSearchBarOption } from './components/DefaultLayout'

type LayoutType = 'none' | 'default' | 'checkout'

export type AppShellContext = {
  environmentName: string
  graphQL: { query: GraphQLContext['query'] }
  layoutType: LayoutType
  path: string
  preview: boolean
  query: { [key: string]: string | string[] | undefined }
  region: Region
  shouldHideFooter?: boolean
  hideSearchBar?: HideSearchBarOption
  hideGoogleOneTap?: boolean
  shouldRenderAppBanner: boolean
  megaNavExperimentEnabled?: boolean
}

export type AppShellProps = {
  appBanner: AppBannerInitialProps | null
  environmentName: string
  layout: LayoutInitialProps
  path: string
}

type AppShellFC = FC<React.PropsWithChildren<AppShellProps>> & {
  getInitialProps: (context: AppShellContext) => Promise<AppShellProps>
}

export const AppShell: AppShellFC = ({
  appBanner,
  children,
  environmentName,
  layout,
  path,
}) => {
  const t = useLocaleText()

  const disableCookieBanner = useCoreFlag('disable-cookie-banner')
  const disableAppBanner = useCoreFlag('disable-app-banner')
  const devWidgetAvailable = ['local', 'dev', 'uat'].includes(environmentName)

  return (
    <>
      <GlobalStyle />
      {!disableCookieBanner ? (
        <OneTrustCookieBanner environmentName={environmentName} />
      ) : null}
      {!disableAppBanner && appBanner && (
        <AppBanner
          title={appBanner.title}
          subtitle={appBanner.subtitle}
          ctaText="Get"
          url={appBanner.url}
          voucherCode={appBanner.voucherCode}
          shouldFormat={appBanner.shouldFormat}
          totalRatingsText={appBanner.totalRatings}
          rating={appBanner.rating}
          dismissTitle={t('close_the_smart_banner')}
          path={path}
        />
      )}
      <Layout {...layout}>{children}</Layout>
      {devWidgetAvailable && /* istanbul ignore next */ <DevWidget />}
    </>
  )
}

AppShell.getInitialProps = async ({
  environmentName,
  graphQL,
  layoutType,
  path,
  preview,
  query,
  region,
  shouldHideFooter,
  hideSearchBar,
  hideGoogleOneTap,
  shouldRenderAppBanner,
  megaNavExperimentEnabled,
}) => {
  const appBannerPromise = shouldRenderAppBanner
    ? getAppBannerInitialProps({ graphQL, preview })
    : null

  const layoutPromise = getLayoutInitialProps({
    graphQL,
    layoutType,
    path,
    preview,
    query,
    region,
    shouldHideFooter,
    hideSearchBar,
    hideGoogleOneTap,
    megaNavExperimentEnabled,
  })

  const [appBanner, layout] = await Promise.all([
    appBannerPromise,
    layoutPromise,
  ])

  const initialProps: AppShellProps = {
    appBanner,
    environmentName,
    layout,
    path,
  }

  return initialProps
}
